<template>
  <div class="page-rooter-fff">
    <headeBar ref="header" title="券包"  left-arrow @click-left="newAppBack" />
    <div class="page-content">
      <van-tabs class="position-box" border swipeable v-model="active" title-active-color="#242831" title-inactive-color="#6C727A" color="#242831" line-height="0.53vw" line-width="45.6vw">
        <van-tab title="待使用/待发放">
          <van-list class="list" :style="`height: calc(100vh - ${pad}px)`" v-if="recordList.length">
            <div class="item"  :class="{'used': item.equityStatus === 2, 'waiting': item.equityStatus === 0}"  v-for="(item, index) in recordList" :key="index">
              <div class="item-l">
                <div><span>¥</span>{{ item.voucherPrice }}</div>
              </div>
              <div class="item-r">
                <div class="item-r-l">
                  <div>{{ item.equityName }}</div>
                  <div><span>{{item.equityStatusName}}</span>{{ item.equityIssuedTimeNotes }}</div>
                </div>
              </div>
            </div>
          </van-list>
          <div v-else class="empty" :style="`height: calc(100vh - ${pad}px)`">
            <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/defaultPic/empty.png" alt="">
            <div>暂无数据</div>
          </div>
        </van-tab>
        <van-tab title="历史记录">
          <van-list class="list" :style="`height: calc(100vh - ${pad}px)`" v-if="historyRecordList.length">
            <div @click="jumpOrderDetail(item)" class="item used" v-for="(item, index) in historyRecordList" :key="index">
              <div class="item-l">
                <div><span>¥</span>{{ item.voucherPrice }}</div>
              </div>
              <div class="item-r">
                <div class="item-r-l">
                  <div>{{ item.equityName }}</div>
                  <div><span>{{item.equityStatusName}}</span>{{ item.equityIssuedTimeNotes }}</div>
                </div>
              </div>
            </div>
          </van-list>
          <div v-else class="empty" :style="`height: calc(100vh - ${pad}px)`">
            <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/defaultPic/empty.png" alt="">
            <div>暂无数据</div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

  </div>
</template>

<script>
import appMixin from "@/mixin/appMixin";
import {newAppBack, getUserIdAndToken, gotoAppPage} from "@/lib/appMethod";
import headeBar from '@/components/app/headBar';
export default {
  data() {
    return {
      finished: false,
      recordList: [],
      historyRecordList: [],
      loading: false,
      refreshing: false,
      active: 0,
      pad: 0
    };
  },
  mixins: [appMixin],
  components: {headeBar},
  created() {
    this.load();
  },
  mounted() {
    this.pad = (this.$refs.header.$el.offsetHeight? this.$refs.header.$el.offsetHeight:0) + 44
  },
  methods: {
    // 页面初次加载
    async load() {
      try {
        this.param = await getUserIdAndToken(this.baseURLApp)
      } catch (err) {
        if(this.isTestEnvironment){
          this.param = {userId: 19553076, token: 'J5IJPmIcAIBorNhC1Mb'}
        }
        console.log("不是APP环境环境错误");
      }
      Object.assign(this.param, {userMembershipCardId: this.$route.query.userMembershipCardId ?? ''})
      this.getList()
    },
    onRefresh() {
      this.getList(true)
    },
    getList(r) {
      this.$axios.post(`${this.baseURLApp}/user/membershipCard/record`, this.param).then(res => {
        console.log(res)
        this.recordList = res.data?.userMembershipCardGiftEquityRecordDTO?.recordList ?? []
        this.historyRecordList = res.data?.userMembershipCardGiftEquityRecordDTO?.historyRecordList ?? []
      })
    },
    jumpOrderDetail(v) {
      if(v.equityStatus === 2){
        gotoAppPage(7, {payOrderNo: v.payOrderNo})
      }
    },
    newAppBack
  },
};
</script>

<style lang="less" scoped>
  .page-rooter-fff{
    background: #F5F5F5;
    min-height: 100vh;
    .page-content{
      //.position-box{
      //  position: relative;
      //}
    }
  }
  ///deep/ .van-tabs__wrap{
  //  position: fixed;
  //  top: 110px;
  //  right: 0;
  //  width: 100%;
  //}

  .list{
    height: 100%;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    overflow: scroll;
    .item + .item{
      margin-top: 32px;
    }
    .item{
      display: flex;
      background-color: white;
      &.waiting{
        .item-l{
          background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/coupon_bg2.png");
        }
        .item-r{
          .item-r-l{
            color: #6C727A;
            div:last-child {
              span {
                background-color: #9AA1A9;
              }
            }
          }
          .item-r-r{
            background-color: #F5F5F5;
            color: #6C727A;

          }
        }
      }
      &.used{
        .item-l{
          background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/coupon_bg3.png");
        }
        .item-r{
          .item-r-l{
            color: #9AA1A9;
            div:last-child {
              span {
                background-color: #BBBBBB;
              }
            }
          }
          .item-r-r{
            background-color: #F5F5F5;
            color: #BBBBBB;
          }
        }
      }
      .item-l{
        background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/coupon_bg1.png") no-repeat center center;
        background-size: 100%;
        width: 168px;
        height: 160px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 48px;
        font-weight: bold;
        span{
          font-size: 28px;
        }
      }
      .item-r{
        display: flex;
        padding: 0 32px 0 24px;
        justify-content: space-between;
        flex-grow: 1;
        .item-r-l{
          color: #242831;
          div:first-child{
            font-weight: bold;
            font-size: 24px;
            margin-top: 36px;
          }
          div:last-child{
            font-size: 20px;
            margin-top: 26px;
            span{
              padding: 4px 8px;
              color: white;
              font-size: 18px;
              background: #242831;
              margin-right: 16px;
            }
          }
        }
        .item-r-r{
          width: 108px;
          height: 40px;
          background: #242831;
          border-radius: 20px;
          color: white;
          font-weight: bold;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          margin-top: 32px;
        }
      }

    }

  }
  .empty{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    min-height: 90vh;
    img{
      width: 160px;
      height: 160px;
    }
    div{
      margin-top: 12px;
      font-size: 24px;
      color: #999;
    }
  }

  /deep/ .van-tab--active{
    font-weight: bold;
  }
  ///deep/ .van-tabs{
  //  height: 100%;
  //}
  /deep/ .van-hairline--top-bottom::after{
    border-top: none;
    border-color: #eee;
  }
  ///deep/ .van-tabs__content, /deep/ .van-tab__pane{
  //  height: calc(100% - 44px);
  //}
</style>
